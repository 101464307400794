import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import { LoginHeader } from "./LoginHeader"

import { t } from "i18next"
import { FixCompanyName, getBannerDataLogin, ShowLoader, HideLoader } from "../functions.js"
import { getRequestOptionsNoAuth } from "../helpers/apiHelper"
import { ModalShowRows } from "../components/ModalShowRows"
import { AlertPopup } from "../components/AlertPopup"


import "../assets/css/login.css"

const GetResault = async endpoint => {
    const response = await fetch(endpoint,getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const EmailRecovery = () => {
    const [vatID, setVatID] = useState('')
    const [clientID, setClientID] = useState('')
    const [isEmptyVatID, setIsEmptyVatID] = useState(false)
    const [isEmptyClientID, setIsEmptyClientID] = useState(false)
    const [isOpenEmailNotAlert, setIsOpenEmailNotAlert] = useState(false)
    const [bannerOne, setBannerOne] = useState({})
    const [emails, setEmails] = useState([ 
        // {email: 'webapp_user@smartdigit.pt'}, {email: 'webapp_user@smartdigit.pt'}, {email: 'webapp_user@smartdigit.pt'}, {email: 'webapp_user@smartdigit.pt'}, {email: 'webapp_user@smartdigit.pt'}
    ])
    const [isOpenModal, setOpenModal] = useState(false)

    const ClosePopup = () => setOpenModal(false)
    const onBackToLogin = () => {
        ClosePopup()
        window.location.href = '/login-email/'
    }

    const RecoverMail = e => {
        e.preventDefault()
        vatID.trim().length === 0 ? setIsEmptyVatID(true) : setIsEmptyVatID(false)
        clientID.trim().length === 0 ? setIsEmptyClientID(true) : setIsEmptyClientID(false)
        if (vatID.trim().length > 0 && clientID.trim().length > 0) {
            try {
                ShowLoader()
                GetResault(`${process.env.REACT_APP_API_DOMAIN}/b2b/users_email_access_recovery?vat_id=${vatID}&customer_number=${clientID}`).then(json => {
                    if (json.data) {
                        if(json.data.length > 0) {
                            setEmails(json.data)
                            setOpenModal(true)
                            document.querySelector('.email_found').classList.add('active')
                            setVatID('')
                            setClientID('')
                        } else {
                            setIsOpenEmailNotAlert(true)
                            setVatID('')
                            setClientID('')
                        }
                    } else {
                        setIsOpenEmailNotAlert(true)
                    }
                })
            } catch (error) {
                setIsOpenEmailNotAlert(true)
                console.error(error)
            } finally {
                HideLoader()
            }
        }
    }
    
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
           // 'B2B - Login - Step 1 - Banner'
           e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.content_type_id === "J8RvMwowm7k2pNOr" && setBannerOne(getBannerDataLogin(e))
        })
    }, [])
    
    return (
        <>
            <div className="login__wrapper">
                <LoginHeader />
                <form autoCapitalize="off" autoComplete="off" autoCorrect="off" className="login__content" onSubmit={RecoverMail}>
                    <div className="login__header__title">{t('niki.b2b.login.recoveraccessemail')}<br />{FixCompanyName(t('niki.b2b.login.monolithunitedkingdom'))}</div>
                    {isEmptyVatID && <div className="alert alert-danger">{t('niki.b2b.login.emptyvatid')}</div>}
                    {isEmptyClientID && <div className="alert alert-danger">{t('niki.b2b.login.emptyclientid')}</div>}
                    <div className="marginbottom10">{t('niki.b2b.login.inputvatid')}</div>
                    <div className="marginbottom10">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t('niki.b2b.login.vatid')}
                            value={vatID}
                            onChange={e => setVatID(e.target.value)}
                        />
                    </div>
                    <div className="marginbottom20">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t('niki.b2b.login.clientid')}
                            value={clientID}
                            onChange={e => setClientID(e.target.value)}
                        />
                    </div>
                    <div className="marginbottom10">
                        <button type="submit" className="btn">{t('niki.b2b.login.recoveremail')}</button>
                    </div>
                    <div className="marginbottom50">
                        <NavLink to="/login-email/" className="btn">{t('niki.b2b.recovery_modal.back_to_login')}</NavLink>
                    </div>
                </form>
                <a href={bannerOne.url} target={bannerOne.target} rel="noopener noreferrer" className="login__banner"><img src={bannerOne.image} alt={bannerOne.title} /></a>
            </div>
            <div className="loader"></div>
            {isOpenEmailNotAlert && <AlertPopup text={t('niki.b2b.alert.emailnotfound')} closeAlert={() => setIsOpenEmailNotAlert(false)}/>}
            {emails?.length > 0 && isOpenModal && <ModalShowRows content={emails} addclass="email_found" ClosePopup={ClosePopup} onClickOneButton={onBackToLogin}
                title='niki.b2b.recovery_modal.title' title1='niki.b2b.recovery_modal.positive_message' buttonOne='niki.b2b.recovery_modal.back_to_login'/>}
        </>
    )
}