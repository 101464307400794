import { useState, useEffect, useRef, useMemo } from "react"
import { useSelector } from 'react-redux'
import { useInfiniteHits } from "react-instantsearch"

import { Product, ProductPlaceholder } from "./ProductCard"

function SortByOptionClickController() {
    const options = document.querySelectorAll('.ais-SortBy-option');
   
    if (options) {
        options.forEach(option => {
            if (option.selected && (option.value !== localStorage.getItem('sortByProducts'))) {
                window.scrollTo(0, 0)
                localStorage.setItem('sortByProducts', `${option.value}`)
                document.querySelector('.sorting_panel__sort').classList.remove('active')
                document.querySelector('.sorting_panel__overlay').classList.remove('active')
            }      
        })
    }

  return null; 
}

export const CustomInfiniteHits = ({ props, setShowQuantityProducts }) => {
    const {
        items,
        // currentPageHits,
        results,
        isLastPage,
        showMore,
        // sendEvent,
    } = useInfiniteHits(props); 
    
    const favorites = localStorage.getItem('favorites')
    const histories =  localStorage.getItem('history.ids')   
    const loaderRef = useRef(null)
    const loadRef = useRef(null)
    const timerRef = useRef(null)

    const search = window.location.search
    const productsView = useSelector(state => state.allProductsContent.productsView)

    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [itemsLength, setItemsLength] = useState(0)

    useEffect(() => {
        if (items.length > 0 && document.querySelector('.products_grid__product')) {
            setShowQuantityProducts(items.length)
            setIsLoading(false)
        }
    }, [items.length, setShowQuantityProducts])

    useEffect(() => {
        if (timerRef.current) { clearTimeout(timerRef.current) }
      
        if (isFetching) {
            timerRef.current = setTimeout(() => {
                setIsFetching(false)
                setIsLoading(false)
                showMore()
            }, 1200)
        }
        
        return () => clearTimeout(timerRef.current);
        // eslint-disable-next-line
    }, [isFetching])

    useEffect(() => {
        const handleScroll = () => {
            if (isLastPage || isFetching || isLoading) return
            if (loaderRef.current && loaderRef.current.getBoundingClientRect().top <= window.innerHeight*3) {
                setIsLoading(true)
                setIsFetching(true)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line
    }, [items, isFetching, isLastPage])

    useEffect(() => {
        if (document.querySelector('.product_in_listing')) {
            document.querySelector('.products_on_page').innerText = items.length
            setIsLoading(false)
        }
        // eslint-disable-next-line
    }, [items.length])

    useEffect(() => {
        if (itemsLength !== results.nbHits) {
            setItemsLength(results.nbHits)
            setLoading(true)
        } 
    }, [results.nbHits, itemsLength])
    
    useEffect(() => {
        const sortFiled = document.querySelectorAll('.ais-SortBy-select');
        let timer;
        clearTimeout(timer);
        if (loadRef?.current && loadRef.current !== sortFiled[0]?.selectedOptions[0]?.value) {
            timer = setTimeout(() => {
                setLoading(true)
                clearTimeout(timer);
            }, 1300);
        }
        loadRef.current = sortFiled[0]?.selectedOptions[0]?.value
    }, [items])

    const hitsMemo = useMemo(() => {
        loading && setLoading(false)
        return (
            <div className={`products_grid paddingbottom0 ${productsView}`}>
                <SortByOptionClickController/>
                {items.map(hit => {
                        if(search && (search.includes('favorites') || search.includes('purchased'))){
                            if (search.includes('favorites')) {
                                return favorites.includes(hit.id) && <Product key={`product${hit.id}`} {...hit} />
                            } else {
                                return histories.includes(hit.id) && <Product key={`product${hit.id}`} {...hit} />
                            }
                        } else {
                            return  <Product key={`product${hit.id}`} {...hit} />
                        } 
                    }  
                )}
            </div>
        )
        // eslint-disable-next-line
    }, [items.length, loading])

    return (
        <>
            {hitsMemo}
            <div ref={loaderRef}></div>
            {isLoading && <div className={`products_grid paddingbottom0 ${productsView}`}>
                <ProductPlaceholder elements={12} />
            </div>}
        </>
    )
}
