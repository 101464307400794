import { xCompanyID, meilisearchUrl, xTenant } from '../config'
import { ShowLoader, HideLoader, CheckTokenLifeTime } from '../functions.js'
import { setIsOpenNoCartAlert, setIsOpenProductNotFoundAlert, setIdDownloadDocument, setErrorMessage, setFavoriteProducts } from '../reduxToolkit/allproduct'

const LangFix = e => e === 'gb' ? 'en' : e

export const getRequestOptions = (method = 'GET', accept, contentType) => {
    return {
        method: method,
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        }
    }
}

export const getRequestApi = async ({ method, accept, contentType, URL, body = null }) => {
    const headers = {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        },
        body
    }

    let response = {}
    try {
        const result = await fetch(URL, headers)
        if (result.status === 401) {
            response = result
            CheckTokenLifeTime()
        } else {
            response = result
        }
    } catch (error) {
        console.error(error)
    }

    return response
}

export const getRequestOptionsNoAuth = (method = 'GET', accept, contentType) => {
    return {
        method: method,
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
        }
    }
}

export const guruRequestOptions = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
    }
}

export const fetchDataPhoneOrEmail = async (url, requestOptions) => {
    const response = await fetch(url, requestOptions)
    return response.ok
}

export const getBanners = async (item_segmentations = '') => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/contents?include=contentType,attachments,contentManagementPlans,contentManagementPlans.attachments${item_segmentations}`, getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const deleteProductFormCart = (productId) => {
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${productId}`, getRequestOptions('DELETE'))
        .then(response => {
            // console.log(response)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
}

export const updateProductInCartPost = async (productId, qty) => {
    const requestOptions = getRequestOptions('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({ qty: qty })

    ShowLoader()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${productId}`, requestOptions)
    const json = await response.json()
    HideLoader()
    return json
}

export const getProductFormSearch = async (codes) => {
    const filterInsert = `(barcode=${codes} OR source_item_id=${codes}) AND company_id=${xCompanyID}`
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
        }
    })
    const json = await response.json()
    return json
}

export const deleteCartsItem = async cartItemID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${cartItemID}`
    const response = await fetch(URL, getRequestOptions('DELETE'))
    return response
}

export const getAllCartsData = async (url) => {
    const response = await getRequestApi({ URL: url })
    const json = await response.json()
    return json
}

export const getAccountBlocked = async (accountEntityId) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account-check/${accountEntityId ? accountEntityId : localStorage.getItem('account.entity.id')}`
    const response = await fetch(URL, getRequestOptions())
    const json = await response.json()
    // return {blocked: true}
    return json
}

export const returnErrorDuringAddProductToCart = (error, dispatch) => {
    if (localStorage.getItem('cart.uuid')) {
        getAllCartsData(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id.real')}`)
            .then(json => {
                let existCart = false
                json.data && json.data.forEach(e => {
                    existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                })
                existCart
                    ? dispatch(setIsOpenProductNotFoundAlert(true))
                    : dispatch(setIsOpenNoCartAlert(true))
            })
            .catch(error => {
                dispatch(setIsOpenProductNotFoundAlert(true))
                console.error(error)
            })
    } else {
        dispatch(setIsOpenProductNotFoundAlert(true))
    }
    console.error(error)
}

export const createNewCart = async (newCartName) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`
    const params = {
        company_id: localStorage.getItem('account.company.hashed'),
        customer_account_id: localStorage.getItem('account.entity.id'),
        status_id: 'J8RvMwowm7k2pNOr',
        realKey: '1',
    }

    if (newCartName) params.matchcode = newCartName
    const body = JSON.stringify(params)

    const response = getRequestApi({ URL, method: 'POST', contentType: 'application/json', body })
    return response
}

export const getEntityData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/user/entity-accounts?include=company,user&realKey=true&type_id=8&itemSegmentations=true`, getRequestOptions())
    const json = await response.json()
    return json
}

export const getOpenPDF = (documentUuid, fileName='file', dispatch, t) => {
    dispatch(setIdDownloadDocument(documentUuid))
    
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${documentUuid}/print`, getRequestOptions('GET', 'text/plain'))
    .then(response => {
        if (!response.ok) {
            dispatch && dispatch(setErrorMessage(`${response.status} ${response.statusText}`))
            throw new Error(`${response.status} ${response.statusText}`);
        }
        return response.blob()
    })
    .then(blob => {           
        const href = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `${fileName}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link);
        URL.revokeObjectURL(href);           
    })
    // .then(response => response.text())
    // .then(url => window.open(`http://${url}`, '_blank', 'noreferrer'))
    .catch(error => {
        dispatch && dispatch(setErrorMessage(t('niki.b2b.feedback.error_return_products') + ' ' + t('niki.b2b.feedback.contact_your_manager') + '.'))
        console.error(error)
    })
    .finally(() => {
        HideLoader()
        dispatch(setIdDownloadDocument(''))
    })
}

export const getAllFavorites = async (dispatch) => {
    fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("favorites")}/search?filter=user_id=${localStorage.getItem('user.id')}&limit=8000&attributesToRetrieve=favoriteable_id`, guruRequestOptions)
        .then(response => response.json())
        .then(json => {
            if (json.hits?.length > 0) {
                const favorites = json.hits.map(i => i.favoriteable_id).join(',')
                localStorage.setItem('favorites', favorites)
                dispatch(setFavoriteProducts(favorites))
            } else {
                localStorage.setItem('favorites', '')
            }
        })
        .catch(error => console.error(error))
}

export const includeForProduct = 'item,item.sale_unit_code,item.itemDescriptions,item.itemPrices,item.itemDiscounts,item.attachment,item.attachments,item.itemKinds,item.itemStocks,item.itemFamily,item.entityAccount'
export const cartHeaderInclude = ['cartDetails', 'cartDetails.item', 'cartDetails.sale_unit_code', 'cartDetails.item.itemDescriptions', 'cartDetails.item.itemPrices', 'cartDetails.item.itemDiscounts', 'cartDetails.item.attachment', 'cartDetails.item.attachments', 'cartDetails.item.itemKinds', 'cartDetails.item.itemStocks', 'cartDetails.item.itemFamily', 'cartDetails.item.entityAccount']

export const getMeilisearchIndexName = (indexName) => `${xTenant}_${xCompanyID}_${indexName}`