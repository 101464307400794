import React, { useEffect, useRef, useState }  from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from "i18next"
import { InstantSearch, Configure, Hits } from 'react-instantsearch'
import { CustomSearchBox, UpdateSearchBrowserURL } from './CustomSearchBox';

import { addCartProduct, setIsLoaded, clearCartContent, setCartHeaders, deleteCartProduct } from "../reduxToolkit/cartSlice"
import { setIsClickOnAllProductsInMainMenu, setIsRefreshMarketingCampaigns, setIsReloadeAllPoduct } from '../reduxToolkit/allproduct'
import { xCompanyID, searchClient, startFacetFilters, priceLineID, xTenant } from './../config.js'
import { PriceFormat, GetTotalNet, HideLoader, ShowLoader, getCartProducts, scrollTop, setCartIdsToStorage, GoToLogin, checkIsAsideFilters } from '../functions'
import { getRequestOptions, getRequestApi, deleteProductFormCart, getMeilisearchIndexName, getBanners, cartHeaderInclude, getAllCartsData, createNewCart, getAllFavorites } from './../helpers/apiHelper'

import { AlertPopup } from "./AlertPopup"
import { WraperForModals } from './WraperForModals'
import { ChangeEntityAccount } from './ChangeEntityAccount'
import { ProductDetails } from './ProductDetails'

import { SearchSingleProduct } from './SearchSingleProduct'

import './../assets/css/mainmenu.css'
import './../assets/css/searchsingleproduct.css'

import { ReactComponent as Menu } from './../assets/svg/mmmenu.svg'
import { ReactComponent as Search } from './../assets/svg/mmsearch.svg'
import { ReactComponent as Account } from './../assets/svg/mmaccount.svg'
import { ReactComponent as Cart } from './../assets/svg/mmcart.svg'
import { ReactComponent as ShoppingCart } from './../assets/svg/mmshoppingcart.svg'
import { ReactComponent as Brands } from './../assets/svg/mmbrands.svg'
import { ReactComponent as Contact } from './../assets/svg/mmcontact.svg'
import { ReactComponent as Marketing } from './../assets/svg/mmmarketing.svg'
import { ReactComponent as AccountOverview } from './../assets/svg/mmaccountoverview.svg'
import { ReactComponent as OrdersHistory } from './../assets/svg/mmordershistory.svg'
import { ReactComponent as InvoicesReturns } from './../assets/svg/mminvoicesreturns.svg'
import { ReactComponent as MyShoppingCarts } from './../assets/svg/mmmyshoppingcarts.svg'
import { ReactComponent as FavoriteProducts } from './../assets/svg/mmfavoriteproducts.svg'
import { ReactComponent as Logout } from './../assets/svg/mmlogout.svg'
import { ReactComponent as CartPlay } from './../assets/svg/mmcartplay.svg'
import { ReactComponent as AllCarts } from './../assets/svg/mmallshoppingcarts.svg'
import { ReactComponent as RedCart } from './../assets/svg/mmredcart.svg'
import { ReactComponent as WhiteCart } from './../assets/svg/mmwhitecart.svg'

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

const LoadCartsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?include=cartDetails&sortedBy=desc`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}

export const getCart = (cartId, dispatch) => {
    ShowLoader()
    try {
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${cartId}?include=${cartHeaderInclude.join(',')}&realKey=1&priceline_external_id=${priceLineID}&warehouse_external_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => {
                if (parseInt(response.status) === 401) GoToLogin()
                return response.json()
            })
            .then(json => {
                if (json.data) {
                    dispatch(setCartHeaders({
                        id: json.data.id,
                        idreal: json.data.id_real,
                        name: json.data.matchcode || ''
                    }))
                    if (json?.data?.cartDetails?.data) {
                        dispatch(clearCartContent())
                        json?.data?.cartDetails?.data.forEach(e => {
                            const cartData = getCartProducts(e)
                            dispatch(addCartProduct(cartData))
                        })
                        dispatch(setIsLoaded('notloaded'))
                    }
                    HideLoader()
                }
            })
    } catch (e) {
        console.error(e)
        HideLoader()
    }
}

export const MainMenu = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const currentDate = new Date()

    const cartContent = useSelector(state => state.cartContent.cartContent)
    const cartSumm = useSelector(state => state.cartContent.summ)
    const cartCount = useSelector(state => state.cartContent.count)
    const cartName = useSelector(state => state.cartContent.name)

    const cartCountLast = cartCount > 5 ? 5 : cartCount

    const mainMenuOverlayEl = useRef(null)
    const searchResultsEl = useRef(null)

    const [isSearchInput, setIsSearchInput] = useState(false)
    const [cartShow, setCartShow] = useState('empty')
    const [cartsDataLoaded, setCartsDataLoaded] = useState(false)
    const [cartList, setCartList] = useState([])
    const [isNeedHideAll, setIsNeedHideAll] = useState(false)
    const [isAccountBlockedModal, setIsAccountBlockedModal] = useState(false)
    const [isChangeEntityAccountModal, setIsChangeEntityAccountModal] = useState(false)

    const idProductDetails = useSelector(state => state.productsContent.idProductDetails)

    const ShowSearchResults = () => searchResultsEl && searchResultsEl.current.classList.add('active')
    const HideSearchResults = () => searchResultsEl && searchResultsEl.current.classList.remove('active')
    const onClickHideAll = () => {
        setIsNeedHideAll(true)
        isSearchInput && setIsSearchInput(false)
    }

    const onClickFullCart = () => {
        setIsNeedHideAll(true)
        navigate("/myshoppingcart/")
    }

    const returnCartProducts = (data) => {
        setCartList([])
        data.forEach(e => {
            const tempObj = {
                id: e.id,
                idReal: e.id_real,
                uuid: e.uuid,
                date: FormatDate(e.created_at),
                cartname: e.matchcode || t('niki.b2b.cartflow.cart'),
                itemscount: (e?.cartDetails?.data?.length) || 0,
                totalnet: (e?.cartDetails?.data?.length > 0 && GetTotalNet(e.cartDetails.data)) || 0
            }
            setCartList(prevState => ([...prevState, tempObj]))
        })
        setCartsDataLoaded(true)
        setCartShow('multiple')
    }

    useEffect(() => {
        if (localStorage.getItem('cart.id')) {
            getCart(localStorage.getItem('cart.id'), dispatch)
        } else {
            try {
                ShowLoader()
                const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails&realKey=1&sortBy=updated_at`

                getAllCartsData(URL)
                    .then(json => {
                        let updateDate = 0
                        let res = {}
                        if (json.data && json.data.length > 0) {
                            json.data.forEach(e => {
                                const date = new Date(e.updated_at).getTime()
                                if (updateDate < date) {
                                    updateDate = date
                                    res = e
                                }
                            })
                            getCart(res.id, dispatch)
                            setCartIdsToStorage(res.uuid, res.id_real, res.id)
                        } else {
                            createNewCart()
                                .then(response => response.json())
                                .then(json => {
                                    if (json.data) {
                                        setCartIdsToStorage(json.data.uuid, json.data.id_real, json.data.id)
                                    }
                                })
                        }
                    })
            } catch (e) {
                console.log(e.name + ': ' + e.message)
            } finally {
                HideLoader()
            }
        }

        if (location.pathname === '/all-products/' || location.pathname === '/') {
            return
        } else {
            getAllFavorites(dispatch)
        }
        // eslint-disable-next-line
    }, [])

    const onShowCart = (e) => {
        LoadCartsData()
        .then(json => {
            ShowLoader()
            if (json.data && json.data.length > 0) {
                const res = json.data
                if (localStorage.getItem('cart.id') && localStorage.getItem('cart.uuid')) {
                    if (res.filter(i => i.uuid === localStorage.getItem('cart.uuid')).length === 1) { 
                        setCartShow('single')
                    } else {
                        getCart(res[0].id, dispatch)
                        returnCartProducts(res)
                        setCartIdsToStorage(res[0].uuid, res[0].id_real, res[0].id)
                        setCartShow('single')
                    }
                } else {
                    returnCartProducts(res)
                }
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    // update banners data
    useEffect(() => {

        if (Boolean(localStorage.getItem('isAccountBlocked'))) {
            setIsAccountBlockedModal(true)
            localStorage.removeItem('isAccountBlocked')
        }

        if (!localStorage.getItem('updateAtBannersMarketingCampaignIds') || localStorage.getItem('updateAtBannersMarketingCampaignIds') === "undefined") { 
            getRequestApi({ URL: `${process.env.REACT_APP_API_DOMAIN}/b2b/marketplace/data-update-check?updated_at=${currentDate.toISOString().replace('T', ' ').slice(0, 19)}` })
                .then(response => response.json())
                .then(data => {
                    if (data?.updated_at) {
                        localStorage.setItem('updateAtBannersMarketingCampaignIds', data.updated_at)
                    }
                })
        }

        const fetchData = async () => { 
            getRequestApi({ URL: `${process.env.REACT_APP_API_DOMAIN}/b2b/marketplace/data-update-check?updated_at=${localStorage.getItem('updateAtBannersMarketingCampaignIds') || currentDate.toISOString().replace('T', ' ').slice(0, 19)}` })
                .then(response => response.json())
                .then(data => {
                    // todo if (data?.has_changes) {  
                        localStorage.setItem('updateAtBannersMarketingCampaignIds', data.updated_at || currentDate.toISOString().replace('T', ' ').slice(0, 19))
                        
                        const entityID = localStorage.getItem('account.entity.id.real')
                        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account/${entityID}?include=realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
                            .then(response => response.json())
                            .then(json => {
                                if (JSON.stringify(json.data.marketingCampaignIds) !== localStorage.getItem('marketingCampaignIds')) { 
                                    localStorage.setItem('marketingCampaignIds',
                                        JSON.stringify(json.data.marketingCampaignIds)
                                            ? JSON.stringify(json.data.marketingCampaignIds)
                                            : '{"new":[],"promo":[],"booklet":[]}'
                                    )
                                    dispatch(setIsRefreshMarketingCampaigns(true))
                                }
                            })
                            .catch(error => console.error(error))

                        getBanners()
                            .then(json => {
                                if (json.data) {
                                    localStorage.setItem('banners_data', JSON.stringify(json.data))
                                }
                            })
                            .catch(error => console.error(error))
                    // }
                })
                .catch(error => console.error(error))
        };
        fetchData()
        // todo
        const interval = setInterval(fetchData, 20 * 60 * 1000);
    
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const MainMenuButtonClick = e => {
        const wasActive = e.target.classList.contains('active')
        document.querySelector('.main_menu__button.active') && document.querySelector('.main_menu__button.active').classList.remove('active')
        document.querySelector('.main_menu__regular.active') && document.querySelector('.main_menu__regular.active').classList.remove('active')
        isSearchInput && setIsSearchInput(false)

        if (wasActive) {
            e.target.classList.remove('active')
            document.querySelector(`.${e.target.dataset.show}`).classList.remove('active')
            mainMenuOverlayEl.current.classList.remove('active')
        } else {
            e.target.classList.add('active')
            document.querySelector(`.${e.target.dataset.show}`).classList.add('active')
            mainMenuOverlayEl.current.classList.add('active')
        }
        
        if (e.target.dataset.show === 'main_menu__search') {
            setIsSearchInput(true)
        } else if (location.pathname === "/all-products/") {
            switch (e.target.dataset.show) {
                case 'main_menu__menu':
                    checkIsAsideFilters(wasActive)
                    break
                case 'main_menu__account':
                    checkIsAsideFilters(wasActive)
                    break
                case 'main_menu__cart':
                    checkIsAsideFilters(wasActive)
                    onShowCart(e)
                    break
                default:
                    break
            }            
        } else if (e.target.dataset.show === "main_menu__cart") { 
            !wasActive && onShowCart(e)
        }
    }
    const hideAll = () => {
        location.pathname === '/all-products/' && checkIsAsideFilters(true)
        document.querySelector('.main_menu__button.active') && document.querySelector('.main_menu__button.active').classList.remove('active')
        document.querySelector('.main_menu__regular.active') && document.querySelector('.main_menu__regular.active').classList.remove('active')
        mainMenuOverlayEl.current.classList.remove('active')
        HideSearchResults()
    }
    
    useEffect(() => {
        let timer;

        if (isNeedHideAll) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                hideAll()
                setIsNeedHideAll(false)
            }, 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isNeedHideAll])

   
    const OpenCart = e => {
        const { id, idReal, uuid } = e.target.dataset
        setCartIdsToStorage(uuid, idReal, id)
        window.location.href = '/myshoppingcart/'
    }

    const DeleteCartItem = e => {
        const CartDetail = e.target.dataset.cartdetail
        dispatch(deleteCartProduct(CartDetail))
        deleteProductFormCart(CartDetail)
    }

    const LogoutClick = () => {
        const country = localStorage.getItem('locale.full')
        const language = localStorage.getItem('locale')
        const lastPath = localStorage.getItem('current.url')
        localStorage.clear()
        localStorage.setItem('locale.full', country)
        localStorage.setItem('locale', language)
        localStorage.setItem('current.url', lastPath)
        window.location.href = '/' 
    }
    
    const clearAllAndHideAll = () => {
        onClickHideAll()
        scrollTop()
    }

    const SearchResultsHit = ({ hit }) => {
        let prodQty = cartContent.filter(i => i.productid === hit.id)[0]?.qty || 0
        const changeHit = { ...hit, prodQtyCart: prodQty }
       
        return (
            <SearchSingleProduct {...changeHit} clearAllAndHideAll={clearAllAndHideAll}/>
        )
    }

    useEffect(() => {
        const local = location.pathname
        switch (local) {
            case "/all-products/":
                break; 
            case "/marketing/":
                break;
            case "/brands/":
                break;
            case "/contact/":
                break;
            default:
                localStorage.setItem('brand_is_selscted', '')
        }
        if (location.pathname === '/all-products/' || location.pathname === '/') {
            getAllFavorites(dispatch)
        }
        // eslint-disable-next-line
    },[location])
    
    const clickOnNavLink = (e) => {   
        if (localStorage.getItem('brand_is_selscted') === 'true' && e.target.pathname === "/all-products/") { 
            navigate('/all-products/')
            localStorage.setItem('allproducts.show', '')
            localStorage.setItem('allproducts.brands', '')
            localStorage.setItem('brand_is_selscted', '')
        } else {
            localStorage.setItem('allproducts.show', '')
            localStorage.setItem('allproducts.brands', '')
        }
        onClickHideAll()
    }

    const searchResults = (query) => {
        query.length > 0 ? ShowSearchResults() : HideSearchResults()
        location.pathname === "/all-products/" && checkIsAsideFilters(query.length === 0)
    }

    const last5Product = cartContent.length > 0 && cartContent.slice(cartContent?.length > 5 ? -5 : 0)

    return (
        <div className="main_menu">
            <div className="main_menu__content">
                <button
                    className="main_menu__button"
                    onClick={MainMenuButtonClick}
                    data-show="main_menu__menu"
                ><Menu /></button>
                <button
                    className="main_menu__button"
                    onClick={MainMenuButtonClick}
                    data-show="main_menu__search"
                ><Search /></button>
                <NavLink to="/" className="main_menu__logo" onClick={onClickHideAll}>
                    <img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" />
                </NavLink>
                <button
                    className="main_menu__button"
                    onClick={MainMenuButtonClick}
                    data-show="main_menu__account"
                ><Account /></button>
                <button
                    className="main_menu__button"
                    onClick={MainMenuButtonClick}
                    data-show="main_menu__cart"
                ><Cart /></button>
            </div>
            <div className="main_menu__menu main_menu__regular">
                <ul className="main_menu__menu__list">
                    <li>{location.pathname === "/all-products/"
                        ? <button onClick={() => {onClickHideAll(); dispatch(setIsClickOnAllProductsInMainMenu(true))}}> <ShoppingCart />{t('niki.b2b.menu.allproducts')}</button>
                        : <NavLink to="/all-products/" onClick={clickOnNavLink}><ShoppingCart />{t('niki.b2b.menu.allproducts')}</NavLink>
                    }</li>
                    <li><NavLink to="/brands/" onClick={clickOnNavLink}><Brands />{t('niki.b2b.menu.brands')}</NavLink></li>
                    <li><NavLink to="/contact/" onClick={clickOnNavLink}><Contact />{t('niki.b2b.menu.contact')}</NavLink></li>
                    <li><NavLink to="/marketing/" onClick={clickOnNavLink}><Marketing />{t('niki.b2b.menu.marketing')}</NavLink></li>
                </ul>
            </div>
            <InstantSearch
                indexName={getMeilisearchIndexName("items")}
                searchClient={searchClient}
            >
                <div className="main_menu__search main_menu__regular">
                    <Configure
                        facetFilters={startFacetFilters}
                        hitsPerPage={10}
                        attributesToHighlight={[]}
                    />
                    {isSearchInput && <CustomSearchBox searchResults={searchResults} />}
                </div>
                <div className="search_results" ref={searchResultsEl} >
                    <div className="search_results_list"><Hits hitComponent={SearchResultsHit} /></div>
                    {location.pathname === "/all-products"
                        ? <button onClick={() => { onClickHideAll(); UpdateSearchBrowserURL(); dispatch(setIsReloadeAllPoduct(true))}} className="search_results_show_all__button">{t('niki.b2b.mainmenu.showallresults')}</button>
                        : <NavLink to={`/all-products`} onClick={onClickHideAll} className="search_results_show_all">{t('niki.b2b.mainmenu.showallresults')}</NavLink>
                    }
                </div>
            </InstantSearch>
            <div className="main_menu__account main_menu__regular">
                <ul>
                    <li><NavLink to="/account/" onClick={onClickHideAll}><AccountOverview />{t('niki.b2b.header.accountoverview')}</NavLink></li>
                    <li><NavLink to="/orders/" onClick={onClickHideAll}><OrdersHistory />{t('niki.b2b.menu.orderhistory')}</NavLink></li>
                    <li><NavLink to="/invoices/" onClick={onClickHideAll}><InvoicesReturns />{t('niki.b2b.menu.invoicesreturns')}</NavLink></li>
                    <li><NavLink to="/shoppingcarts/" onClick={onClickHideAll}><MyShoppingCarts />{t('niki.b2b.cart.myshoppingcarts')}</NavLink></li>
                    <li>{window.location.pathname === '/all-products/'
                        ? <button disabled={true}><FavoriteProducts />{t('niki.b2b.menu.favoriteproducts')}</button>
                        : <NavLink to="/all-products/?show=favorites" onClick={onClickHideAll}><FavoriteProducts />{t('niki.b2b.menu.favoriteproducts')}</NavLink>
                    }</li>
                    {localStorage.getItem('has_many_entity_account') === 'true' && <li>
                        <button onClick={() => setIsChangeEntityAccountModal(true)}><AccountOverview />{t('niki.b2b.change_account')}</button>
                    </li>}
                    <li className="devider"></li>
                    <li><button onClick={LogoutClick}><Logout />{t('niki.b2b.menu.logout')}</button></li>
                </ul>
            </div>
            <div className="main_menu__cart main_menu__regular">
                {/* <div className="main_menu__caer__preloader"></div> */}
                
                {cartShow === 'empty' && cartsDataLoaded && <div className="no_carts">{t('niki.b2b.mainmenu.nocarts')}</div>}
                {cartShow === 'multiple' && !cartsDataLoaded && <div className="main_menu__caer__preloader"></div>}
                {cartShow === 'multiple' && cartsDataLoaded &&
                    <div className="main_menu__shopping_carts">
                        <div className="main_menu__cart__title">{t('niki.b2b.cart.select')}</div>
                        <div className="main_menu__cart__under_title">{t('niki.b2b.cart.activatecartbelow')}</div>
                        <ul className="main_menu__cart__list">
                        {cartList.map((e,i)=>
                            <li key={`shoppingcart${i}`}>
                                <button
                                    className="main_menu__cart__list__button"
                                    data-id={e.id}
                                    data-idreal={e.idReal}
                                    data-uuid={e.uuid}
                                    onClick={OpenCart}
                                >
                                    <span>{e.idReal} &mdash; {e.cartname}</span>
                                    <span>{e.itemscount} {t('niki.b2b.debts.skus')}</span>
                                    <span>{PriceFormat(e.totalnet)}</span>
                                    <span>{e.date}</span>
                                    <CartPlay />
                                </button>
                            </li>)}
                        </ul>
                    </div>
                }
                {cartShow === 'single'&&
                    <>
                        <div className="main_menu__single_cart active">
                            <div className="main_menu__single_cart__title">{t('niki.b2b.cart.shoppingcart')}</div>
                            <div className="main_menu__single_cart__under_title">
                                <div>{localStorage.getItem('cart.id.real')} {cartName.length > 0 ? cartName : t('niki.b2b.cart.shoppingcart')}</div>
                                <div>{t('niki.b2b.cart.last')} {cartCountLast} of {cartCount} {t('niki.b2b.cart.items')}</div>
                            </div>
                            <div className="main_menu__single_cart__content">
                                {last5Product?.length > 0 && last5Product.map((e,i)=>
                                <table className="main_menu__single_cart__content__table" key={`carttable${i}`}>
                                    <thead>
                                        <tr>
                                            <th colSpan="4">{e.name}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t('niki.b2b.cart.ref')}: {e.productitemid}</td>
                                            <td>{t('niki.b2b.mainmenu.uni')}: {PriceFormat(e.price)}</td>
                                            <td className="main_menu__single_cart__content__table__summ">{PriceFormat(parseInt(e.qty)*parseFloat(e.price))}</td>
                                            <td>
                                                <button
                                                    className="diasble_svg"
                                                    onClick={DeleteCartItem}
                                                    data-cartdetail={e.id}
                                                ><RedCart /></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('niki.b2b.mainmenu.unitbox')}: {e.qtyinbox}</td>
                                            <td>{t('niki.b2b.mainmenu.qty')}: {e.qty}</td>
                                            <td colSpan="2"></td>
                                        </tr>
                                    </tbody>
                                </table>)}
                            </div>
                            <div className="main_menu__single_cart__footer">
                                <button className="btn btn-primary button_height_45" disabled={location.pathname === "/myshoppingcart/"} onClick={onClickFullCart}>{t('niki.b2b.mainmenu.fullcart')}<WhiteCart /></button>
                                <div className="right">
                                    <span className="main_menu__single_cart__footer__carttotal">{t('niki.b2b.cart.carttotal')}</span>
                                    <span className="main_menu__single_cart__footer__exclvat">{t('niki.b2b.cart.exclvat')}</span>
                                    <span className="main_menu__single_cart__footer__summ">{PriceFormat(cartSumm)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="main_menu__cart__all_carts">
                            <NavLink to="/shoppingcarts/" onClick={onClickHideAll} className="btn btn-block">{t('niki.b2b.mainmenu.allcarts')}
                                <AllCarts />
                            </NavLink>
                        </div>
                    </>
                }
            </div>
            <WraperForModals />
            <div className="main_menu__overlay" onClick={hideAll} ref={mainMenuOverlayEl}></div>
            {isAccountBlockedModal && <AlertPopup text={t('niki.b2b.entity_account_blocked')} closeAlert={() => setIsAccountBlockedModal(false)}
                titleFirstButton={'niki.b2b.menu.contact'} towButtons={true} onFirstButtonClick={() => navigate('/contact/')}
            />}
            {isChangeEntityAccountModal && <ChangeEntityAccount closeModal={() => setIsChangeEntityAccountModal(false)} />}
            {idProductDetails && <ProductDetails productID={idProductDetails} />}
        </div>
    )
}