import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"

import { catalogsTypeID } from './../config.js'
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from "../functions"
import { defaultCompanyBooklet } from '../config.js'

import { BreadCrumbs } from "../components/BreadCrumbs"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import { ReactComponent as Download } from './../assets/svg/productcatalogdownload.svg'

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const ProductCatalog = () => {

    const [catalogsData,setCatalogsData] = useState([])
    const [currentCatalog, setCurrentCatalog] = useState({})
    const [isDowandImage,setIsDowandImage] = useState(true)
    
    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?include=attachments&search=typeableType.id:${catalogsTypeID}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {    
            const data = json.data || []
            if (data.length) {
                const marketingDataArray = []
                
                data.sort((a, b) => {
                    return new Date(a.created_at) - new Date(b.created_at);
                })

                data.forEach((e,i) => {
                    let pdfPath = '' || defaultCompanyBooklet
                    let thumbPath = '' || defaultCompanyBooklet
                    
                    if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                        e.attachments.data.forEach(el => {
                            if (el.extension === 'pdf') {
                                pdfPath = el?.awsFileUrl
                            } else {
                                thumbPath = el?.awsFileUrl
                            } 
                        })
                    }

                    const tempObj = {
                        id: e.id,
                        edition: e.matchcode,
                        from: e.start_at,
                        to: e.finish_at,
                        link: pdfPath,
                        image: thumbPath,
                    }
                    marketingDataArray.push(tempObj)
                })
                setCatalogsData(marketingDataArray)
                setCurrentCatalog(marketingDataArray[0])
            } 
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            setIsDowandImage(false)
        })
    }, [])
    
    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/product-catalogs/','title':t('niki.b2b.productcatalogs.h1')}]} />
            <div className="product_catalog__content">
                <h1>{t('niki.b2b.productcatalogs.h1')}</h1>

                <div className="product_catalog__single_big">
                    {currentCatalog.image || isDowandImage
                        ? <LoadableImage src={currentCatalog.image} alt={t('niki.b2b.productcatalogs.h1')} height={'300px'}/>
                        : <img src={defaultCompanyBooklet} alt=""/>
                    }
                    <div>{t('niki.b2b.productcatalogs.h1')}</div>
                    {currentCatalog.from
                        ? <div className="product_catalog__single_big__dates">{FormatDate(currentCatalog.from)} - {FormatDate(currentCatalog.to)}</div>
                        : <div className="product_catalog__single_big__dates">&nbsp;</div>
                    }
                </div>

                <h2 className="marginbottom20">{t('niki.b2b.productcatalogs.full')}</h2>
                <div className="marginbottom20">{t('niki.b2b.productcatalogs.downloadfull')}.</div>
                {currentCatalog && <div className="marginbottom20">
                    <a className="btn btn-info" target="_blank" rel="noopener noreferrer nofollow" href={currentCatalog.link}>{t('niki.b2b.productcatalogs.downloadcurrent')}</a>
                </div>}
                <div className="marginbottom40"><NavLink to="/promotional-booklets/" className="btn">{t('niki.b2b.productcatalogs.viewbooklets')}</NavLink></div>

                <h2 className="center marginbottom40">{t('niki.b2b.productcatalogs.full')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('niki.b2b.productcatalogs.edition')}</th>
                            <th>{t('niki.b2b.product.date')}</th>
                            <th style={{paddingRight: 3}}>{t('niki.b2b.process.download')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {catalogsData.map((e,i)=>
                        <tr key={`productcatalog${i}`}>
                            <td>{e.edition}</td>
                            <td>{`${FormatDate(e.from)}-${FormatDate(e.to)}`}</td>
                            <td className="product_catalogs_colum__save">{e.link && <a href={e.link} target="_blank" rel="noopener noreferrer nofollow"><Download /></a>}</td>
                        </tr>
                    )}
                    </tbody>
                </table>

            </div>
        </div>
    )
}