import { useState, useEffect } from "react"
import { t } from "i18next"

import { BreadCrumbs } from "../components/BreadCrumbs"
import { getRequestOptions } from '../helpers/apiHelper'
import { privacyPolicyID } from '../config'

const PrivacyPolicyContent = () => {
    const [data, setData] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/privacy-policies-public`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setData(json.data.filter(e => e.real_id === privacyPolicyID)[0].body)
            json.data && setDataLoaded(true)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="static_page_content">
            <h1>{t('niki.b2b.footer.privacypolicy')}</h1>
            {!dataLoaded
                ? <ContentPlaceholder />
                : <div className="dangerouslyInnerHTM__container_text" dangerouslySetInnerHTML={{ __html: data }} />
            }
        </div>
    )
}

const ContentPlaceholder = () => {
    return (
        <>
            <div className="animated_placeholder marginbottom15em width25"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom2em width75"></div>
            <div className="animated_placeholder marginbottom15em width25"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom2em width75"></div>
            <div className="animated_placeholder marginbottom15em width25"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom2em width75"></div>
        </>
    )
}

export const PrivacyPolicy = () => {
    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/privacy-policy/','title':t('niki.b2b.footer.privacypolicy')}]} />
            <PrivacyPolicyContent />
        </div>
    )
}