import React, { useEffect, useState } from "react"
import { t } from "i18next"
import { useParams, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { priceLineID } from './../config.js'
import { ShowLoader, HideLoader } from "../functions.js"
import { getRequestOptions, getAllCartsData, getOpenPDF } from '../helpers/apiHelper'

import { BreadCrumbs } from "./../components/BreadCrumbs"
import { TwoContacts } from "../components/TwoContacts"
import LoadingDots from "../components/LoadingDots/LoadingDots"

import { ReactComponent as Account } from './../assets/svg/gridaccount.svg'
import { ReactComponent as Orders } from './../assets/svg/gridorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/gridshoppingcarts.svg'
import { ReactComponent as Download } from './../assets/svg/processdownload.svg'

const ReturnSubmitedContent = props => {
    const dispatch = useDispatch()

    const returnID = props.returnID
    const idDownloadDocument = useSelector(state => state.allProductsContent.idDownloadDocument)

    const [keySerial, setKeySerial] = useState(null)
    const [cartsQuantity, setCartsQuantity] = useState(0)

    useEffect(()=>{
        try {
            getAllCartsData(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}`)
                .then(json => json.data && setCartsQuantity(parseInt(json.data.length)))
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${returnID}?include=details,status&realKey=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setKeySerial(`${json.data?.key}-${json.data?.serial}-${json.data.number}`)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [returnID])
    
    return (
        <>
            <div className="breadcrumbs__wrapper">
                <BreadCrumbs itemlist={[{'url':'/invoices/','title':t('niki.b2b.menu.invoices')}]} />
                <ol className="breadcrumbs__steps">
                    <li></li>
                    <li></li>
                    <li className="current"><span>{t('niki.b2b.feedback.confirmation')}</span></li>
                </ol>
            </div>

            <div className="return_content">
                <h1 className="marginbottom10">{t('niki.b2b.returnproducts.registered')}</h1>

                <div className="return_submited__red">
                    <h2>{t('niki.b2b.invoices.return')} { keySerial ? `${keySerial}` : <LoadingDots/> }</h2>
                    <div className="marginbottom20">{t('niki.b2b.returnproducts.redtitle')}.<br />
                    {t('niki.b2b.feedback.received2')}.</div>
                    <button disabled={idDownloadDocument}
                        className="return_submited__red__button btn_svg__stroke_white"
                        onClick={() => getOpenPDF(returnID, keySerial, dispatch, t)}
                    >
                        {idDownloadDocument
                            ? <div className="wrapper_spiner_download_thanks">{t('niki.b2b.process.download')}<div className="spinner_ring"><div></div><div></div><div></div><div></div></div></div>
                            : <>{t('niki.b2b.process.download')} <Download /></>
                        }
                    </button>
                </div>

                <h2 className="marginbottom20">{t('niki.b2b.feedback.otheroptons')}</h2>
                <ul className="return_submited__grid">
                    <li><NavLink to="/account/"><Account />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                    <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                    <li><NavLink to="/shoppingcarts/"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')}</NavLink>
                        {cartsQuantity > 0 && <span className="alert">{cartsQuantity}</span>}
                    </li>
                    {/*<li><a href="/returns/"><Returns />Returns</a></li>*/}
                </ul>
                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
        </>
    )
}

export const ReturnSubmited = () => {
    const { id } = useParams()
    return (
        <div className="content">
            <ReturnSubmitedContent returnID={id} />
        </div>   
    )
}