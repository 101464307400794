// todo delete this page
import React, { useState} from "react"
import { t } from "i18next"
import { useSearchParams } from 'react-router-dom'
import { InstantSearch, Configure, SearchBox, InfiniteHits } from 'react-instantsearch'

import { xCompanyID, searchClient, startFacetFilters, priceLineID } from '../config.js'
import { translationsForSearchBox } from '../functions'
import { getMeilisearchIndexName } from '../helpers/apiHelper'
import { BreadCrumbs } from "../components/BreadCrumbs"

import './../assets/css/searchsingleproduct.css'

const ProductsPerPage = 20

const SearchContent = props => {
    
    const defaultSearchQuery = props.query || ''

    const [query,setQuery] = useState(props.query || '')

    const ChangeSearchValue = e => {
        const localValue = e.target.value
        setQuery(localValue)
        const currentURL = new URL(window.location.href)
        currentURL.searchParams.set('q', localValue)
        window.history.replaceState(null, '', currentURL)
    }

    return (
        <>
            <BreadCrumbs itemlist={[{url: `/search/?q=${query}`, title: `${t('niki.b2b.header.search')} «${query}»`},]} />
            <InstantSearch
                indexName={getMeilisearchIndexName("items")}
                searchClient={searchClient}
            >
                <div className="search_content">
                    <h1>{t('niki.b2b.search.h1')} &laquo;{query}&raquo;</h1>
                    <SearchBox
                        placeholder={t('niki.b2b.header.searchproducts')}
                        onChange={ChangeSearchValue}
                        defaultRefinement={defaultSearchQuery}
                        translations={translationsForSearchBox()}
                    />
                </div>
                <Configure
                    facetFilters={startFacetFilters}
                    hitsPerPage={ProductsPerPage}
                    attributesToHighlight={[]}
                    attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions',`${xCompanyID === '4' ? 'brand' : 'trademark' }`,'country']}
                />
                <InfiniteHits />
            </InstantSearch>

        </>
    )
}

export const Search = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.get("q")
    return (
        <div className="content">
            <SearchContent query={query} />
        </div>
    )
}