import React, { useRef, useState }  from 'react'
import { useDispatch } from "react-redux"

import { PriceFormat, CapacityString, returnStockStatusText, LangFix, checkValueOrNonInteger, GetHistoryData } from '../../functions.js'
import { changeQuickAddProductCount } from "../../reduxToolkit/cartSlice"
import { setIdProductDetails } from '../../reduxToolkit/productsSlice'
import { priceLineID, xCompanyID } from '../../config'
import { OrderHistoryPopup } from '../OrderHistoryPopup'

import '../../assets/css/shoppingcart.css'
import { ReactComponent as Brand } from '../../assets/svg/scbrand.svg'
import { ReactComponent as Box } from '../../assets/svg/scbox.svg'
import { ReactComponent as New } from '../../assets/svg/ppnew.svg'
import { ReactComponent as Booklet } from '../../assets/svg/ppbooklet.svg'
// todo history logic
import { ReactComponent as OrdersHistory } from '../../assets/svg/scordershistory.svg'

const selectedLanguage = LangFix(localStorage.getItem('locale'))

export const QuickAddProductTemplate = ({ data }) => {

    const dispatch = useDispatch()

    const [isOpenHistoryProduct, setIsOpenHistoryProduct] = useState(false)
    
    const qtyEl = useRef(null)

    const getProductBrand = () => {
        const _brand = xCompanyID === '4'
            ? data?.brand?.name ? data.brand.name[LangFix(selectedLanguage)] || data.brand?.name : ' '
            : data?.trademark?.name ? data.trademark.name[LangFix(selectedLanguage)] || data.trademark?.name : ' '
        return data.itemBrand?.data?.name || _brand
    }

    // const ProductLink = `/productpage/${ProductID}/`
    const ProductQtyInBox = parseInt(data?.itemUnitBarcodeByUnitCodeBox?.coefficient) || parseInt(data.itemUnitBarcodeByUnitCodeBox?.coefficient) || 1
    const [qty, setQty] = useState(ProductQtyInBox)

    const ProductCountry = data.country ? data.country.iso_3166_2.toLowerCase() : null
    
    let ProductPrice = data?.itemPrices[priceLineID]?.unit_price || 0
    let ProductPriceOld = 0
    if (data.is_discount && data.itemDiscounts) {
        ProductPriceOld = ProductPrice
        ProductPrice = parseFloat(data?.itemDiscounts[priceLineID]?.unit_price)
    }

    const unitCodeTranslations = data.main_unit_code_translations[selectedLanguage] || data.capacity_unit_code

    const ProductCapacity = CapacityString(data.capacity, data.capacity_unit_code, ProductPrice)
    let { stockStatus, stockText } = returnStockStatusText(data.instock, unitCodeTranslations)

    const inputChangeQtyProduct = e => {
        const value = e.target.value
        if (typeof value === "string" && !value) {
            setQty(0)
            return
        }
       
        if (value.includes('.') && value.slice(value.indexOf('.')).length > 3) return
        
        const inputValue = checkValueOrNonInteger(value, data.sale_unit_code)
        if (inputValue === '') return 

        const res = data.sale_unit_code === 'KG' ? inputValue : parseInt(inputValue)

        if (inputValue !== qty) {
            dispatch(changeQuickAddProductCount(res))
        }
        setQty(res)
    }

    const plusQtyProduct = () => {
        const sum = +qtyEl.current.value+ProductQtyInBox
        const resultSum = typeof sum === "string" ? parseFloat(sum) : sum
        const resPlus = Number.isInteger(resultSum) ? resultSum : Number(resultSum.toFixed(2))

        setQty(resPlus)
        dispatch(changeQuickAddProductCount(resPlus))
    }

    const minusQtyProduct = () => {
        const subtractionResult = +qtyEl.current.value - ProductQtyInBox
        const difference = Number.isInteger(subtractionResult) ? subtractionResult : Number(subtractionResult.toFixed(2))
       
        setQty(difference)
        dispatch(changeQuickAddProductCount(difference))
    }

    return (
        <>
            <div className="quick_add_product_data">
                <div>
                    <div onClick={() => dispatch(setIdProductDetails(data.id))} className="quick_add_product_data__image">
                        <img src={data.productImage} alt={data.productName} loading="lazy" />
                    </div>
                    <div className="quick_add_product_data__item_id">{data.source_item_id}</div>
                    {ProductCountry && <div className={[`quick_add_product_data__country`,`flag-icon-${ProductCountry}`].join(' ')}></div>}
                    {data.is_new && <New className="quick_add_product_data__is_new" />}
                    {data.is_booklet && <Booklet className="quick_add_product_data__is_booklet" />}
                </div>
                <div>
                    <div onClick={() => dispatch(setIdProductDetails(data.id))} className="quick_add_product_data__name" >{data.productName}</div>
                    <div className="quick_add_product_data__info"><Brand />{getProductBrand()}</div>
                    <div className="quick_add_product_data__info"><Box />{ProductQtyInBox} {unitCodeTranslations}</div>
                    <div className={[`quick_add_product_data__stock`,stockStatus].join(' ')}><span></span>{stockText}</div>
                    {ProductPriceOld > 0 && <div className="quick_add_product_data__discount">{Math.round((ProductPrice-ProductPriceOld)/ProductPriceOld*100)}%</div>}
                </div>
                <div>
                    <span className="quick_add_product_data__price">{PriceFormat(ProductPrice)}</span>
                    <span className="quick_add_product_data__neaar_price">{ProductCapacity}</span>
                </div>
                <div className="quick_add_product_data__buttons">
                    {GetHistoryData(data.id).length > 0
                        ? <button onClick={() => setIsOpenHistoryProduct(true)} className="quick_add_product_data__orders_history">
                            <OrdersHistory />
                        </button>
                        : <span></span>
                    }
                    <div className="quick_add_product_data__cart">
                        <button
                            disabled={qty <= 0}
                            className="quick_add_product_data__cart_button minus"
                            onClick={minusQtyProduct}
                        >-</button>
                        <input
                            className="quick_add_product_data__cart_input"
                            value={qty}
                            onChange={inputChangeQtyProduct} 
                            ref={qtyEl}
                        />
                        <button
                            className="quick_add_product_data__cart_button plus"
                            onClick={plusQtyProduct}
                        >+</button>
                    </div>
                </div>
            </div>
            {GetHistoryData(data.id).length > 0 && isOpenHistoryProduct && 
                    <OrderHistoryPopup
                    matchcode={data.productName}
                    imgsrc={data.productImage}
                    sale_unit_code={unitCodeTranslations}
                    qty_in_box={ProductQtyInBox}
                    item_id={data.source_item_id}    
                    product_id={data.id}
                    setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                />
            }
        </>
    )
}