import React, { useEffect, useRef, useState } from 'react'
import { useSearchBox } from 'react-instantsearch';
import { t } from "i18next"

import './../assets/css/mainmenu.css'

import { ReactComponent as Search } from './../assets/svg/scsearch.svg'

export const UpdateSearchBrowserURL = (value) => {
    const currentURL = new URL(window.location.href)
    const searchValue = value || localStorage.getItem('allproducts.search')

    if (searchValue && searchValue.length > 0) {
        currentURL.searchParams.set('search', searchValue)
    } else {
        currentURL.searchParams.delete('search')
    }

    window.history.replaceState(null, '', currentURL)
    localStorage.setItem('url.back',currentURL.search)
}

export function CustomSearchBox({ props, searchResults, isUpdateBrowserURL = false }) {
    const { query, refine } = useSearchBox(props)
    const [inputValue, setInputValue] = useState(query || localStorage.getItem('allproducts.search'))
    const inputRef = useRef(null)

    useEffect(() => {
        isUpdateBrowserURL && UpdateSearchBrowserURL()
        // eslint-disable-next-line
        return (() => searchResults(''))
    }, [])

    const setQuery = (value) => {
        searchResults && searchResults(value)
        setInputValue(value)
        refine(value)
        value ? localStorage.setItem('allproducts.search', value) : localStorage.removeItem('allproducts.search')
        isUpdateBrowserURL && UpdateSearchBrowserURL(value)
    }

    return (
        <div className='ais-SearchBox-form'>
            <form
                action=""
                role="search"
                noValidate
                onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (inputRef.current) {
                        inputRef.current.blur();
                    }
                }}
                onReset={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    searchResults && searchResults('')
                    setQuery('')
                    localStorage.removeItem('allproducts.search')

                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }}
            >
                <input
                    ref={inputRef}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder={t('niki.b2b.header.search')}
                    spellCheck={false}
                    maxLength={512}
                    type="search"
                    value={inputValue}
                    onChange={(event) => {
                        setQuery(event.currentTarget.value);
                    }}
                    autoFocus
                />
                <button type="submit"><Search /></button>
            </form>
        </div>
    );
}