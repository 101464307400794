import { useState, useEffect, useRef, useMemo } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { t } from "i18next"

import { LangFix, scrollTop, ShowLoader, HideLoader } from "../functions"
import { searchClient, priceLineID, startFacetFilters, bookletsTypeID, xCompanyID } from "../config"
import { getRequestOptions, getMeilisearchIndexName } from '../helpers/apiHelper'
import { setIsClickOnAllProductsInMainMenu, setIsRefreshMarketingCampaigns, setProductsView, setIsReloadeAllPoduct } from '../reduxToolkit/allproduct'
import { InstantSearch, Configure, Stats, ClearRefinements, CurrentRefinements, SortBy, useRefinementList } from "react-instantsearch"

import { BreadCrumbs } from "../components/BreadCrumbs"
import { CustomSearchBox } from "../components/CustomSearchBox"
import { CustomInfiniteHits } from "../components/CustomInfiniteHits"

import { ReactComponent as StockStatus } from '../assets/svg/alstockstatus.svg'
import { ReactComponent as SortStatus } from '../assets/svg/alsortstatus.svg'
import { ReactComponent as ShowGrid } from '../assets/svg/alshowgrid.svg'
import { ReactComponent as ShowList } from '../assets/svg/alshowlist.svg'
import { ReactComponent as Heart } from '../assets/svg/apheart.svg'
import { ReactComponent as Booklet } from '../assets/svg/apbooklet.svg'
import { ReactComponent as Tag } from '../assets/svg/aptag.svg'
import { ReactComponent as Discount } from '../assets/svg/discount.svg'
import { ReactComponent as Purchased } from '../assets/svg/appurchased.svg'
import { ReactComponent as ToUp } from '../assets/svg/toUp.svg'
import { ReactComponent as Search } from '../assets/svg/scsearch.svg'

const filterNames = [ 'Families','Kinds', 'Categories', 'Brands', 'Countries']
const filterTranslations = {
    'Brands': t('niki.b2b.menu.brands'),
    'Categories': t('niki.b2b.allproducts.categories'),
    'Countries': t('niki.b2b.allproducts.countries'),
    'Families': t('niki.b2b.allproducts.families'),
    'Kinds': t('niki.b2b.allproducts.kinds')
}

const returnBrandValueForCompany = () => xCompanyID === '4' ? 'brand' : 'trademark' 

const filterRefinement = {
    'Brands': `${returnBrandValueForCompany()}.name`,
    'Categories': `categories.${LangFix(localStorage.getItem('locale'))}`,
    'Countries': `country.name_translated.${LangFix(localStorage.getItem('locale'))}`,
    'Families': 'family',
    'Kinds': `kinds.${LangFix(localStorage.getItem('locale'))}`,
    'Company': 'company_id',
    'ItemPrices': 'itemPrices',
    'itemStocks': 'itemStocks',
}
const defaultFilterRefinement = {}
const returnDefaultShowFilters = () => {
    return {
        'allitems': '',
        'favorites': `id IN [${localStorage.getItem('favorites')}]`,
        'booklet': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]`
            : `is_booklet=true`,
        'new': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.new}]`
            : `is_new=true`,
        'discount': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.promo}]`
            : `is_discount=true`,
        'purchased': `id IN [${localStorage.getItem('history.ids')}]`
    }
}

const filterTitles = {
    'allitems': t('niki.b2b.menu.allproducts'),
    'favorites': t('niki.b2b.menu.favorites'),
    'booklet': t('niki.b2b.allproducts.marketingcampaigns'),
    'new': t('niki.b2b.allproducts.newitems'),
    'discount': t('niki.b2b.allproducts.discounts'),
    'purchased': t('niki.b2b.allproducts.alreadypurchased')
}

!localStorage.getItem('product.view') && localStorage.setItem('product.view','grid')
localStorage.setItem('allproducts.show','')
localStorage.setItem('allproducts.search','')
localStorage.setItem('allproducts.brands','')

function CustomRefinementList(props) {

    const { items, refine } = useRefinementList(props)
    const sortedItems = items.sort((a, b) => {
        if (!a.isRefined && b.isRefined) {
            return 1;
        }
        return a.label.localeCompare(b.label)
    })
    
    return (
        <ul className="filter_unit__list">
            {sortedItems.map((item) => (
                <li key={item.value} data-search={item.label.toLowerCase()}>
                    <label className="filter_unit__label">
                        <input
                            className="filter_checkbox"
                            type="checkbox"
                            value={item.value}
                            checked={item.isRefined}
                            onChange={() => {
                                refine(item.value)
                            }}
                        />
                        {item.label}
                        <span className="filter_unit__count">{item.count}</span>
                    </label>
                </li>
            ))}
        </ul>
    )
}

const UpdateBrowserURL = () => {
    const currentURL = new URL(window.location.href)

    const searchValue = localStorage.getItem('allproducts.search')
    if (searchValue && searchValue.length > 0) {
        currentURL.searchParams.set('search', searchValue)
    } else {
        currentURL.searchParams.delete('search')
    }
    
    const showValue = localStorage.getItem('allproducts.show')
    if (showValue === 'allitems' || showValue === '') {
        currentURL.searchParams.delete('show')
    } else {
        currentURL.searchParams.set('show',showValue)
    }

    const brandsValue = localStorage.getItem('allproducts.brands')
    if (brandsValue === '') {
        currentURL.searchParams.delete('brand')
    } else {
        currentURL.searchParams.set('brand',brandsValue)
    }

    window.history.replaceState(null, '', currentURL)
    localStorage.setItem('url.back',currentURL.search)
}

export const AllProducts = () => {

    // check config company
    useEffect(() => {
        if (xCompanyID === '4' && !filterNames.includes('GroupResponsible')) {
            filterNames.push('GroupResponsible')
            filterTranslations.GroupResponsible = t('niki.b2b.group_responsible')
            filterRefinement.GroupResponsible = 'group_responsible'
        }
    }, [])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isUpdatePageFromMainMenu = useSelector(state => state.allProductsContent.isClickOnAllProductsInMainMenu)
    const isReloadeAllPoduct = useSelector(state => state.allProductsContent.isReloadeAllPoduct)
    const isOpenNoCartAlert = useSelector(state => state.allProductsContent.isOpenNoCartAlert)
    const isOpenModalCreateNewCart = useSelector(state => state.allProductsContent.isOpenModalCreateNewCart)
    const isRefreshMarketingCampaigns = useSelector(state => state.allProductsContent.isRefreshMarketingCampaigns)
    const favoriteProducts = useSelector(state => state.allProductsContent.favoriteProducts)
    // const isClickOnBannerProducts = useSelector(state => state.allProductsContent.isClickOnBannerProducts)
    // const brandName = useSelector(state => state.allProductsContent.brandName)

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()

    const [brand, setBrand] = useState(searchParams.get("brand"))
    const [defaultSearchQuery, setDefaultSearchQuery] = useState("")
    const [show, setShow] = useState(searchParams.get("show") || "")
    const currentShowValue = show ? show : 'allitems'
    const booklet = searchParams.get("booklet") || null
    const banner = searchParams.get("banner") || null
    const IDs = searchParams.get("ids") || null

    const defaultShowFilters = returnDefaultShowFilters()
    const [showFilters, setShowFilters] = useState(defaultShowFilters)
    const defaultFacet = showFilters[currentShowValue]

    const [isLoading, setIsLoading] = useState(false)
    const [isSort, setIsSort] = useState(false)
    const [isResetProductsPerPage, setResetProductsPerPage] = useState(false)
    const [allProducts, setAllProducts] = useState(0)
    const [showQuantityProducts, setShowQuantityProducts] = useState(0)
    const [H1, setH1] = useState(t('niki.b2b.menu.allproducts'))
    const [selectedFilters, setSelectedFilters] = useState(0)
    const [facetFilters, setFacetFilters] = useState([...startFacetFilters,defaultFacet])
    const [sortType, setSortType] = useState(show || 'allitems')
    const [productView, setProductView] = useState('')
    const [topFilters, setTopFilters] = useState('')
    const [inStockFilters, setInStockFilters] = useState('')
    const [purchasedFilters, setPurchasedFilters] = useState('')
    // eslint-disable-next-line
    const [refinementListPars, setRefinementListPars] = useState(brand ? { [`${returnBrandValueForCompany()}.name`]: [brand] } : {})
    const [filters, setFilters] = useState('')
    const [isSearchInput, setIsSearchInput] = useState(false)

    const asideFilters = useRef(null)
    const buttonRefAllProducts = useRef(null)

    const clearLocalStorageDependency = () => {
        localStorage.setItem('booklet.data', JSON.stringify({}))
        localStorage.setItem('banner.data', JSON.stringify({}))
    }
    useEffect(() => {
        if (isRefreshMarketingCampaigns) {
            setShowFilters(returnDefaultShowFilters())
            dispatch(setIsRefreshMarketingCampaigns(false))
        }
        // eslint-disable-next-line
    }, [isRefreshMarketingCampaigns])

    useEffect(() => {
        if (localStorage.getItem('history.ids')) {
            setShowFilters(prev => ({...prev, purchased: `id IN [${localStorage.getItem('history.ids')}]`}))
        }  
        setBrand(searchParams.get("brand"))
        setDefaultSearchQuery(searchParams.get("search"))
        setShow(searchParams.get("show") )
        scrollTop()
        setProductView(localStorage.getItem('product.view'))
        dispatch(setProductsView(localStorage.getItem('product.view')))

        document.querySelector(`.aside_filters__content__button__show_hide`) && document.querySelector(`.aside_filters__content__button__show_hide`).classList.remove('display_none')

        return () => {
            if (localStorage.getItem('is_on_product_card') === 'false') {
                clearLocalStorageDependency()
                localStorage.setItem('allproducts.show', '')
                localStorage.setItem('allproducts.search','')
                localStorage.setItem('allproducts.brands','')
                localStorage.setItem('url.back', '')
                defaultFilterRefinement['Brands'] = ''
            }
            localStorage.removeItem('sortByProducts')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isReloadeAllPoduct) {
            if (searchParams.get("brand")) {
                localStorage.setItem('allproducts.brands', searchParams.get("brand"))
                setBrand(searchParams.get("brand"))
                setRefinementListPars({ [`${returnBrandValueForCompany()}.name`]: [searchParams.get("brand")] })
            }
            setIsLoading(true)
            const reloadeAll = debounce(() => {
                setIsLoading(false);
                dispatch(setIsReloadeAllPoduct(false))
            }, 500)
            reloadeAll()
        }
        // eslint-disable-next-line
    }, [isReloadeAllPoduct])

    useEffect(() => {
        if (booklet) {
            const bookletData = JSON.parse(localStorage.getItem('booklet.data'))
            if (Object.keys(bookletData).length > 0) {
                bookletData?.productsIdArray?.length && setFilters(`id IN [${bookletData.productsIdArray}]`)
            } else if (Object.keys(bookletData).length === 0) {
                ShowLoader()
                fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?include=items&search=typeableType.id:${bookletsTypeID}&get_related_items_id=1`,getRequestOptions())
                .then(response => response.json())
                    .then(json => {
                        if (json.data) {
                            const data = json.data
                            data.length > 0 && data.forEach((e) => {
                                if (e.id === booklet && e.related_items?.length) {
                                    setFilters(`id IN ${e.related_items}]`)
                                }   
                            })
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => HideLoader())
            }
        } else if (banner) {
            setShowQuantityProducts(0)
            const bannerData = JSON.parse(localStorage.getItem('banner.data'))
            setFilters('')
            if (bannerData && Object.keys(bannerData).length > 0 && bannerData?.productsIdArray?.length) {
                if (Number(bannerData?.productsIdArray[0]) > 0) {
                    setFilters(`real_id IN [${bannerData.productsIdArray}]`)
                } else {
                    setFilters(`id IN [${bannerData.productsIdArray}]`)
                }
            } else if (!bannerData || Object.keys(bannerData).length === 0) {
                const data = JSON.parse(localStorage.getItem('banners_data')) || []
                
                if (data.length > 0) {
                    ShowLoader()
                    const searchBanner = data.filter(el => el.json && el.json.includes(banner))
                    const arrayBannersFormJson = searchBanner.length === 1 ? JSON.parse(searchBanner[0].json).images : []
                    const bannerData = arrayBannersFormJson.filter(el => el[banner])
                    bannerData.length === 1 && setFilters(`id IN [${bannerData[0][banner].slice(4).split(',')}]`)
                    HideLoader()
                }
            }
            setResetProductsPerPage(prev => !prev)
        } else if (brand) {
            localStorage.setItem('allproducts.brands', brand)
        }
        // eslint-disable-next-line
    }, [booklet, banner, brand])

    useEffect(() => {
        if (IDs && IDs?.length > 16) {setFilters(`id IN [${IDs}]`)}
    }, [IDs])

    // useEffect(() => {
    //     if (isClickOnBannerProducts) {
    //         changeBrand()
    //         dispatch(setIsClickOnBannerProducts(false))
    //     }
    //     // eslint-disable-next-line
    // }, [isClickOnBannerProducts])

    useEffect(() => {
        if (defaultSearchQuery && defaultSearchQuery.length > 0) {
            localStorage.setItem('allproducts.search', defaultSearchQuery)
        }
    
        if (show && show.length > 0) {
            const show = localStorage.getItem('allproducts.show')
            if (isSort) {
                localStorage.setItem('allproducts.show', show)
                setH1(filterTitles[show])
            } else {
                localStorage.setItem('allproducts.show', show)
                setH1(filterTitles[show])
            }
        }
    }, [defaultSearchQuery, show, isSort])
    
    useEffect(() => {
        if (favoriteProducts && localStorage.getItem('favorites')) {
            const refer = localStorage.getItem('favorites')
            showFilters.favorites.slice(5).split(',').length !== refer?.split(',').length && setShowFilters(prev => ({ ...prev, favorites: `id IN [${refer}]` }))
        }
        // eslint-disable-next-line
    }, [favoriteProducts])

    useEffect(() => {
        selectedFilters === 0 &&  scrollTop()
    }, [selectedFilters])

    // start logic for scroll top
    const refScrollButton = useRef(null)
    const [isScrollingUp, setIsScrollingUp] = useState(false);
    const [isShowScrollButton, setIsShowScrollButton] = useState(false)    
    const [previousScrollY, setPreviousScrollY] = useState(0);
 
    useEffect(() => {
        const handleScroll = debounce(() => {
            const currentScrollY = window.scrollY;
            const isUp = currentScrollY < previousScrollY;
            setIsScrollingUp(isUp);
            setPreviousScrollY(currentScrollY);
        }, 150);
 
        window.addEventListener('scroll', handleScroll);
 
        return () => window.removeEventListener('scroll', handleScroll);
    }, [previousScrollY]);
 
    useEffect(() => {
        if (showQuantityProducts >= 36 && isScrollingUp) {
            setIsShowScrollButton(true)
            refScrollButton.current = previousScrollY
        }
        // eslint-disable-next-line
    }, [showQuantityProducts, isScrollingUp])
 
    useEffect(() => {
        if (isShowScrollButton && previousScrollY > refScrollButton?.current) {
            setIsShowScrollButton(false)
        }
        // eslint-disable-next-line
    }, [previousScrollY, isScrollingUp, refScrollButton?.current])
 
    const onScrollTopClick = () => {
        scrollTop()
        refScrollButton.current = 0
        setIsShowScrollButton(false)
    }
    // end logic for scroll top
    const ResetResults = useMemo((e) => {
        if (e) {
            let selectedFiltersCount = 0
            const refinements = e?.refinementList
            
            selectedFiltersCount = refinements === undefined && !selectedFiltersCount && brand ? 1 : 0
            
            if (refinements) {
                for (let key in refinements) {
                    selectedFiltersCount += refinements[key].length
                    if (key === 'brand .name') {
                        if (refinements[key].length === 1) {
                            localStorage.setItem('allproducts.brands', refinements[key][0])
                        } else {
                            localStorage.setItem('allproducts.brands', '')
                        }
                    }
                }
            }
            setSelectedFilters(selectedFiltersCount)
            // todo
            UpdateBrowserURL()
        }
        // eslint-disable-next-line
    }, [])

    const sortingPanelClick = e => {
        const toShow = `.${e.target.dataset.show}`
        const isVisible = document.querySelector(toShow).classList.contains('active')
        document.querySelector('.sorting_panel__regular.active') && document.querySelector('.sorting_panel__regular.active').classList.remove('active')
        if (isVisible) {
            document.querySelector(toShow).classList.remove('active')
            document.querySelector('.sorting_panel__overlay').classList.remove('active')
        } else {
            document.querySelector(toShow).classList.add('active')
            document.querySelector('.sorting_panel__overlay').classList.add('active')
        }
    }

    const HideSortingPanel = () => {
        document.querySelector('.sorting_panel__regular.active') && document.querySelector('.sorting_panel__regular.active').classList.remove('active')
        document.querySelector('.sorting_panel__overlay').classList.remove('active')
    }

    const SortTypeChange = e => {
        setIsShowScrollButton(false)
        setShowQuantityProducts(0)
        !brand && setSelectedFilters(0)
        setResetProductsPerPage(prev => !prev)
        setIsSort(true)
        setH1(e.target.dataset.title)
        const whatTotShow = e.target.dataset.show
        const thisValue = e.target.dataset.filter
        localStorage.setItem('what.to.show',whatTotShow)
        window.scrollTo(0,0)
        document.querySelector('.sorting_panel__overlay').classList.remove('active')
        document.querySelector('.sorting_panel__types').classList.remove('active')
        document.querySelector('.sorting_panel__types__button.active') && document.querySelector('.sorting_panel__types__button.active').classList.remove('active')
        e.target.classList.add('active')
        setSortType(e.target.dataset.show)
        setTopFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, inStockFilters, purchasedFilters])
        localStorage.setItem('allproducts.show',e.target.dataset.show)
        UpdateBrowserURL()
    }
    
    const resetFilters = () => {
        setShowQuantityProducts(0)
        setResetProductsPerPage(prev => !prev)
        setIsSort(true)
        window.scrollTo(0,0)
    }

    const ChangeInStock = e => {
        resetFilters()
        const thisValue = e.target.checked ? 'is_in_stock=true' : ''
        setInStockFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, topFilters, purchasedFilters])
    }

    const ChangePurchased = e => {
        resetFilters()
        const thisValue = e.target.checked ? `id IN [${localStorage.getItem('history.ids')}]` : ''
        setPurchasedFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, topFilters, inStockFilters])
    }

    const ShowHideAsideFilter = () => {
        if (asideFilters.current.classList.contains('active')) {
            HideAsideFilter()
        } else {
            ShowAsideFilter()
        }
    }
    const ShowAsideFilter = () => asideFilters.current.classList.add('active')
    const HideAsideFilter = () => asideFilters.current.classList.remove('active')
    const ExpandCollapseFilterUnit = e => e.target.parentNode.classList.toggle('collapsed')
    const GridListSwitcher = e => setProductView(e.target.classList.contains('show_list') ? 'grid' : 'list')

    const FilterUnitSearch = e => {
        const ThisValue = e.target.value.trim().toLowerCase()
        const WhatFind = `li[data-search*="${ThisValue}"]`
        const ListParent = e.target.parentNode.parentNode.querySelector('.filter_unit__list')
        if (ThisValue.length > 0) {
            ListParent.classList.add('normal_height')
            ListParent.querySelectorAll('li').forEach(e => e.style.display = 'none')
            ListParent.querySelectorAll(WhatFind).forEach(e => e.style.display = '')
        } else {
            ListParent.classList.remove('normal_height')
            ListParent.querySelectorAll('li').forEach(e => e.style.display = '')
        }
    }      

    useEffect(() => {
        document.querySelectorAll(`.products_grid`).forEach(e => e.className = `products_grid paddingbottom0 ${productView}`)
        localStorage.setItem('product.view', productView)
        dispatch(setProductsView(productView))
        // eslint-disable-next-line
    }, [productView])

    const onClearCurrentSelecion = () => {
        navigate('/all-products/')
        setFilters('')
        setResetProductsPerPage(prev => !prev)
        clearLocalStorageDependency()
        scrollTop()
    }

    useEffect(() => {
        isOpenNoCartAlert || isOpenModalCreateNewCart
            ? document.querySelector(`.aside_filters__content__button__show_hide`) && document.querySelector(`.aside_filters__content__button__show_hide`).classList.add('display_none')
            : document.querySelector(`.aside_filters__content__button__show_hide`) && document.querySelector(`.aside_filters__content__button__show_hide`).classList.remove('display_none')
    }, [isOpenNoCartAlert, isOpenModalCreateNewCart])
    
    useEffect(() => {
        if (isUpdatePageFromMainMenu) {
            buttonRefAllProducts.current.click()
            setInStockFilters('')
            setPurchasedFilters('')
            clearLocalStorageDependency()
            localStorage.setItem('allproducts.show', '')
            localStorage.setItem('allproducts.search','')
            localStorage.setItem('allproducts.brands','')
            defaultFilterRefinement['Brands'] = ''
            setSelectedFilters(0)
            setBrand('')
            dispatch(setIsClickOnAllProductsInMainMenu(false))
            onClearCurrentSelecion()
        }
        // eslint-disable-next-line
    }, [isUpdatePageFromMainMenu])

    useEffect(() => {
        if (allProducts) {
            const selectedRefinement = document.querySelectorAll('.aside_filters__content__inner__active_filters__list .ais-CurrentRefinements-category') || []
            setSelectedFilters(selectedRefinement.length)
        }
    }, [allProducts])
    
    const indexName = getMeilisearchIndexName("items")
    const sortByParameter = localStorage.getItem('sortByProducts') ? localStorage.getItem('sortByProducts') : indexName+':source_item_id:asc'

    return (<> {isLoading
        ? <></>
        : <>
            <BreadCrumbs itemlist={[{url: '/all-products/', title: t('niki.b2b.menu.allproducts')},]} />
            <InstantSearch
                indexName={indexName}
                searchClient={searchClient}
                onSearchStateChange={ResetResults}
                initialUiState={{
                    [indexName] : {
                        sortBy: sortByParameter,
                        refinementList: refinementListPars,
                        query: localStorage.getItem('allproducts.search') || ''
                    },
                }}
                future={{
                    preserveSharedStateOnUnmount: true,
                    persistHierarchicalRootCount: true
                }}
                key={isResetProductsPerPage}
                searchParameters={filters}
            >
                <Configure
                    facetFilters={facetFilters}
                    hitsPerPage={12}
                    attributesToHighlight={[]}
                    filters={filters}
                    attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions',`${xCompanyID === '4' ? 'brand' : 'trademark' }`,'country','kinds']}
                />
                
                <div className="all_products">
                    <h1>{t(H1)}</h1>
                    {isSearchInput || localStorage.getItem('allproducts.search')
                        ? <CustomSearchBox isUpdateBrowserURL={true} searchResults={(val) => !val && setIsSearchInput(false)} />
                        : <div className='ais-SearchBox-form'>
                            <form action="" role="search">
                                <input onClick={() => setIsSearchInput(true)} maxLength={512} type="search" value={""} placeholder={t('niki.b2b.header.search')} />
                                <button type="submit"><Search /></button>
                            </form>
                        </div>
                    }
                </div>
                
                <CustomInfiniteHits setShowQuantityProducts={setShowQuantityProducts} allProducts={allProducts} />
                
                <div className="sorting_panel">
                    <div className="sorting_panel__content">
                        <button
                            className={[`sorting_panel__button`,`sort_kind`,sortType].join(' ')}
                            onClick={sortingPanelClick}
                            data-show="sorting_panel__types"
                        >
                            <span className="all">{t('niki.b2b.product.all')}</span>
                            <Heart className="show_heart" />
                            <Booklet className="show_book" />
                            <Tag className="show_tag" />
                            <Discount className="show_discount" />
                            <Purchased className="show_refresh" />
                        </button>
                        <button
                            className="sorting_panel__button stock_status"
                            onClick={sortingPanelClick}
                            data-show="sorting_panel__stock"
                        ><StockStatus /></button>
                        {showQuantityProducts 
                            ? <div className="sorting_panel__info">
                                <span>{Math.ceil(showQuantityProducts/12)}</span>
                                -<span className="page_number">{showQuantityProducts}</span>
                                /  <Stats translations={{
                                    rootElementText({ nbHits }) {
                                        setAllProducts(nbHits)
                                        return `${nbHits.toLocaleString()}`
                                    }
                                }} />
                            </div>
                            : <div className="sorting_panel__info"></div>
                        }
                        <button
                            className="sorting_panel__button"
                            onClick={sortingPanelClick}
                            data-show="sorting_panel__sort"
                        ><SortStatus /></button>
                        <button
                            className={[`sorting_panel__button`,`how_show`,`show_${productView}`].join(' ')}
                            onClick={GridListSwitcher}
                        >
                            <ShowGrid className="show_grid" />
                            <ShowList className="show_list" />
                        </button>
                    </div>
                    <div className="sorting_panel__types sorting_panel__regular">
                        <button
                            className={[`sorting_panel__types__button`,sortType === 'allitems' && 'active'].join(' ')}
                            onClick={SortTypeChange}
                            data-show="allitems"
                            data-filter=""
                            data-title={filterTitles['allitems']}
                            ref={buttonRefAllProducts}
                        >{t('niki.b2b.product.all')}</button>
                        <button
                            className={[`sorting_panel__types__button`,sortType === 'favorites' && 'active'].join(' ')}
                            onClick={SortTypeChange}
                            data-show="favorites"
                            data-filter={showFilters['favorites']}
                            data-title={filterTitles['favorites']}
                        ><Heart /></button>
                        <button
                            className={[`sorting_panel__types__button`,sortType === 'booklet' && 'active'].join(' ')}
                            onClick={SortTypeChange}
                            data-show="booklet"
                            data-filter={showFilters['booklet']}
                            data-title={filterTitles['booklet']}
                        ><Booklet /></button>
                        <button
                            className={[`sorting_panel__types__button`,sortType === 'new' && 'active'].join(' ')}
                            onClick={SortTypeChange}
                            data-show="new"
                            data-filter={showFilters['new']}
                            data-title={filterTitles['new']}
                        ><Tag /></button>
                        <button
                            className={[`sorting_panel__types__button`,sortType === 'discount' && 'active'].join(' ')}
                            onClick={SortTypeChange}
                            data-show="discount"
                            data-filter={showFilters['discount']}
                            data-title={filterTitles['niki.b2b.allproducts.discounts']}
                        ><Discount /></button>
                        <button
                            className={[`sorting_panel__types__button`,sortType === 'purchased' && 'active'].join(' ')}
                            onClick={SortTypeChange}
                            data-show="purchased"
                            data-filter={showFilters['purchased']}
                            data-title={filterTitles['purchased']}
                        ><Purchased /></button>
                    </div>
                    <div className="sorting_panel__stock sorting_panel__regular">
                        <label><input type="checkbox" onChange={ChangeInStock} checked={!!inStockFilters}/>{t('niki.b2b.allproducts.instock')}</label>
                        <label><input type="checkbox" onChange={ChangePurchased} checked={!!purchasedFilters}/>{t('niki.b2b.allproducts.alreadypurchased')}</label>
                    </div>
                    <div className="sorting_panel__sort sorting_panel__regular">
                        <SortBy
                            items={[
                                { value: `${getMeilisearchIndexName("items")}:source_item_id:asc`, label: t('niki.b2b.allproducts.ref_product') },
                                { value: `${getMeilisearchIndexName("items")}:matchcode:asc`, label: t('niki.b2b.allproducts.family')+" ↗" },
                                { value: `${getMeilisearchIndexName("items")}:matchcode:desc`, label: t('niki.b2b.allproducts.family')+" ↘" },
                                { value: `${getMeilisearchIndexName("items")}:itemPrices.${priceLineID}.unit_price:asc`, label: t('niki.b2b.allproducts.price')+" ↗" },
                                { value: `${getMeilisearchIndexName("items")}:itemPrices.${priceLineID}.unit_price:desc`, label: t('niki.b2b.allproducts.price')+" ↘" }
                            ]}
                        />
                    </div>
                    <div className="sorting_panel__overlay" onClick={HideSortingPanel}></div>
                </div>

                <div className="aside_filters" ref={asideFilters}>
                    <div className="aside_filters__content">
                        <div className="aside_filters__content__inner">
                            <div className="aside_filters__content__inner__active_filters">{t('niki.b2b.allproducts.activefilters')}</div>
                            <div className="aside_filters__content__inner__active_filters__list"
                                onClick={() => {
                                    if (brand) {
                                        const currentURL = new URL(window.location.href)
                                        currentURL.searchParams.delete('brand')
                                        window.history.replaceState(null, '', currentURL)
                                        localStorage.setItem('allproducts.brands', '')
                                        setRefinementListPars({})
                                    }
                                }}
                            >
                                <ClearRefinements translations={{ resetButtonText: t('niki.b2b.allproducts.clear_all_filters') }} />
                                <CurrentRefinements onClick={(e) => {
                                    e.stopPropagation()
                                    if (e.target?.parentNode?.innerText?.includes(brand)) {
                                        const currentURL = new URL(window.location.href)
                                        currentURL.searchParams.delete('brand')
                                        window.history.replaceState(null, '', currentURL)
                                        localStorage.setItem('allproducts.brands', '')
                                        setRefinementListPars({})
                                    }
                                }} />
                            </div>
                            {filterNames.map((e,i) =>
                                <div className="filter_unit" key={`filterunit${i}`}>
                                    <div className="filter_unit__header" onClick={ExpandCollapseFilterUnit}>{filterTranslations[e]}</div>
                                    <div className="filter_unit__search_wrapper">
                                        <input
                                            type="search"
                                            className="filter_unit__search"
                                            placeholder={t('niki.b2b.header.search')}
                                            onChange={FilterUnitSearch}
                                        />
                                    </div>
                                    <CustomRefinementList
                                        attribute={filterRefinement[e]}
                                        limit={1000}
                                    />
                                </div>
                            )}
                        </div>
                        <button
                            className="aside_filters__content__button__show_hide" 
                            onClick={ShowHideAsideFilter}
                            data-count={selectedFilters}
                        ></button>
                    </div>
                    {(booklet || banner || IDs?.length > 16) && <button
                        className={["aside_filters__content__button__show_hide", "button__celar_filter_products"].join(' ')}
                        onClick={onClearCurrentSelecion}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="32px" height="32px" viewBox="0 0 32 32" id="icon">
                            <path d="M22.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z"/>
                            <path d="M26,6H4V9.171l7.4142,7.4143L12,17.171V26h4V24h2v2a2,2,0,0,1-2,2H12a2,2,0,0,1-2-2V18L2.5858,10.5853A2,2,0,0,1,2,9.171V6A2,2,0,0,1,4,4H26Z" stroke-width="1"/>
                            <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" fill="none" width="32" height="32" />
                        </svg>
                    </button>}
                    <div className="aside_filters__overlay" onClick={HideAsideFilter}></div>
                </div>
                <div className={isShowScrollButton ? ["scroll_button_top", "scroll_button_top_active"].join(' ') : "scroll_button_top"} onClick={onScrollTopClick}>
                    <div className="cart_icon_round">
                        <ToUp />
                    </div>
                    <div className="scroll_button_top_text">{t('niki.b2b.scroll')}</div>
                </div>
            </InstantSearch>
        </>}
    </>)
}