import { useDispatch, useSelector } from 'react-redux'
import { ShowLoader, HideLoader } from '../functions'
import { getRequestOptions, getAllFavorites } from '../helpers/apiHelper'
import { setFavoriteProducts } from '../reduxToolkit/allproduct'
import './../assets/css/allproducts.css'

import { ReactComponent as HeartWhite } from './../assets/svg/heartwhite.svg'
import { ReactComponent as HeartRed } from './../assets/svg/heartred.svg'

export const FavoritesButton = ({ productId, details = false }) => {
    
    const dispatch = useDispatch()

    const favoriteProducts = useSelector(state => state.allProductsContent.favoriteProducts)
    
    const FavoriteButtonClick = e => {
        const thisElement = e.target
        const favoritesLocal = localStorage.getItem('favorites')
        const setFavorite = () => { 
            if (localStorage.getItem('favorites')) {
                let tempArr = localStorage.getItem('favorites').split(',')
                tempArr.push(productId)
                dispatch(setFavoriteProducts(tempArr))
                localStorage.setItem('favorites',tempArr.join(','))
            } else {
                dispatch(setFavoriteProducts([productId]))
                localStorage.setItem('favorites', productId)
            }
            thisElement.classList.remove('false')
            thisElement.classList.add('true')
        }
        
        if (thisElement.classList.contains('true')) {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/items/${productId}/favorites`,getRequestOptions('DELETE'))
            .then(response => {
                if (response.ok) {
                    thisElement.classList.remove('true')
                    thisElement.classList.add('false')
                    dispatch(setFavoriteProducts(favoritesLocal.split(',').filter(p => p !== productId)))
                    localStorage.setItem('favorites', favoritesLocal.split(',').filter(p => p !== productId).join(','))
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/items/${productId}/favorites`,getRequestOptions('POST'))
            .then(response => {
                if (response.ok) {
                    setFavorite()
                }
                return response.json()
            })
            .then(json => {
                if (json.message === 'Duplicated Favorite in Database.') {
                    getAllFavorites(dispatch)
                    setFavorite()
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    return (
        <button onClick={FavoriteButtonClick}
            className={[`buttons_area__button`, details && 'button_favorite_details', favoriteProducts.includes(productId)].join(' ')}
        >
            <HeartWhite className="heartwhite" />
            {favoriteProducts.includes(productId) && <HeartRed className="heartred" />}
        </button>
                   
    )
}