import { useRef, useState, useEffect } from "react"
import { t } from "i18next"
import { useDispatch } from 'react-redux'
import { setIsOpenDeleteProductModal, setIsDeleteProduct } from '../reduxToolkit/cartSlice'
import './../assets/css/alertpopup.css'

export const DeleteProductAlert = () => {
    const dispatch = useDispatch()

    const alertWrapperEl = useRef(null)

    const [isClose, setIsClose] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    useEffect(() => {
        let timer;
        clearTimeout(timer);
        if (isClose) {
            timer = setTimeout(() => dispatch(setIsOpenDeleteProductModal(false)), 400)
        } else {
            timer = setTimeout(() => alertWrapperEl.current.classList.add('active'), 200); 
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isClose, isDelete])

    const onCloseAlertPopup = () => {
        dispatch(setIsDeleteProduct(false))
        setIsClose(true)
        alertWrapperEl.current.classList.remove('active')
    }
    const onConfirmDeleteion = () => {
        dispatch(setIsDeleteProduct(true))
        alertWrapperEl.current.classList.remove('active')
        setIsClose(true)
        setIsDelete(true)
    }

    return (
        <div className="shopping_carts__alert__wrapper" ref={alertWrapperEl}>
            <div className="shopping_carts__alert" style={{top: 'calc(40% - 102px)'}}>
                <div className="shopping_carts__alert__text">{t('niki.b2b.shoppingcart.delete_product')}</div>
                <div className="shopping_carts__alert__buttons">
                    <button className="btn" onClick={onCloseAlertPopup}>{t('niki.b2b.shoppingcarts.no')}</button>
                    <button className="btn btn-primary" onClick={onConfirmDeleteion}>{t('niki.b2b.shoppingcarts.yes')}</button>
                </div>
            </div>
            <div className="shopping_carts__alert__overlay" onClick={onCloseAlertPopup}></div>
        </div>
    )
}