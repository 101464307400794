import { t } from 'i18next'
import { xCompanyID, xTenant } from '../config'


export const PageMaintenance = () => {

    return (
        <>
            <header className="header_ervice_page">
                <div className={[`header_white__inner wrapper`,'overduehide'].join(' ')}>
                    <div className="main_logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></div>
                </div>
            </header>
            <div className="nomatch_content">
                <div className="service_page_title">{t('niki.b2b.maintenance')}!</div>            
                <h2 className="you_can_use_search">{t('niki.b2b.maintenance.apologies_inconvenience')}!</h2>
                <div className="you_can_use_search">Dear clients, our B2B webshop is undergoing scheduled maintenance from 05.03.2025 18:30 to 05.03.2025 23:30.</div>

                {/* <div>{t('niki.b2b.maintenance.undergoing_scheduled_maintenance_enhance')}. {t('niki.b2b.maintenance.be_back_online_shortly')}!</div>
                <div className="you_can_use_search" style={{marginTop: 20}}>{t('niki.b2b.maintenance.thanks_patience_understanding')}.</div> */}
            </div>
            <footer className="footer"></footer>
        </>  
    )
}