import React, { useEffect, useState, useLayoutEffect } from 'react'
import Clarity from '@microsoft/clarity';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { EnterCode } from './login/login'
import { SelectAccount } from './login/SelectAccount'
import { LoginPhone } from './login/LoginPhone'
import { LoginEmail } from './login/LoginEmail'
import { EmailRecovery } from './login/EmailRecovery'
import { PhoneRecovery } from './login/PhoneRecovery'
import { MainPage } from './pages/MainPage'
import { AllProducts } from './pages/AllProducts'
import { Header } from "./components/Header"
import { MainMenu } from "./components/MainMenu"
import { ProductPage } from './pages/ProductPage'
import { NoMatch } from './staticpages/NoMatch'
import { MyShoppingCart } from './pages/MyShoppingCart'
import { Checkout } from './pages/Checkout'
import { ThankYou } from './pages/ThankYou'
import { Account } from './staticpages/Account'
import { ShoppingCarts } from './pages/ShoppingCarts'
import { ShoppingCart } from './staticpages/ShoppingCart'
import { Feedback } from './pages/Feedback'
import { SubmitFeedback } from './staticpages/SubmitFeedback'
import { FeedbackDetails } from './staticpages/FeedbackDetails'
import { FeedbackComplete } from './staticpages/FeedbackComplete'
import { Brands } from './staticpages/Brands'
import { Marketing } from './staticpages/Marketing'
import { Invoices } from './pages/Invoices'
import { Process } from './pages/Process'
import { Orders } from './pages/Orders'
import { Order } from './pages/Order'
import { Debts } from './pages/Debts'
import { Search } from './staticpages/Search'
import { Contacts } from './staticpages/Contacts'
import { ProductCatalog } from './staticpages/ProductCatalog'
import { PromotionalBooklets } from './staticpages/PromotionalBooklets'
import { Videos } from './staticpages/Videos'
import { ReturnProducts } from './pages/ReturnProducts'
import { ReturnSubmited } from './staticpages/ReturnSubmited'
import { DeliveryAddresses } from './staticpages/DeliveryAddresses'
import { OTPRead } from './OTPRead'
import { PrivacyPolicy } from './staticpages/PrivacyPolicy'
import { MandatoryDisclosures } from './staticpages/MandatoryDisclosures'
// import { TermsSale } from './staticpages/TermsSale'
import { PageMaintenance } from './staticpages/PageMaintenance'
import { companyGoogleTagId, companyClarityId, redirectionSites } from "./config"

const siteDomain = window.location.hostname
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return { width, height }
}

const App = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const isMANTAINECE_MODE = useSelector(state => state.allProductsContent.isMANTAINECE_MODE)

    useEffect(() => {
        const whereToRedir = `https://${redirectionSites[siteDomain]}${window.location.pathname}${window.location.search}`
        setWindowDimensions(getWindowDimensions())
        if (windowDimensions.width > 1024 && siteDomain !== 'localhost') window.location.href = whereToRedir
        // eslint-disable-next-line
    }, [windowDimensions.width])

    useLayoutEffect(() => {
        const script_gtagmanager = document.createElement('script');
        script_gtagmanager.id = 'script_gtagmanager';
        script_gtagmanager.src = `https://www.googletagmanager.com/gtag/js?id=${companyGoogleTagId}`;
        document.head.appendChild(script_gtagmanager);

        const script_gtag = document.createElement('script');
        script_gtag.id = 'script_gtag';
        script_gtag.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${companyGoogleTagId}');`
        document.head.appendChild(script_gtag);

        companyClarityId && Clarity.init(companyClarityId)
    }, [])

    return (
        <Router>
            {isMANTAINECE_MODE
                ? <Routes>
                    <Route path="*" element={<PageMaintenance />} />
                </Routes>
                : (localStorage.getItem('enter.shop') && parseInt(localStorage.getItem('enter.shop')) === 1)
                    ? <>
                        <Header />
                        <MainMenu />
                        <Routes>
                            {/* <Route path="/scanner" element={<BarcodeScanner />}></Route> */}
                            <Route path="/" element={<MainPage />}></Route>
                            {/* <Route path="/terms-sale/" element={<TermsSale />}></Route> */}
                            <Route path="/mandatory-disclosures/" element={<MandatoryDisclosures />}></Route>
                            <Route path="/privacy-policy/" element={<PrivacyPolicy />}></Route>
                            <Route path="/deliveryaddresses" element={<DeliveryAddresses />}></Route>
                            <Route path="/return-submited/:id" element={<ReturnSubmited />}></Route>
                            <Route path="/returnproducts/:id" element={<ReturnProducts />}></Route>
                            <Route path="/videos" element={<Videos />}></Route>
                            <Route path="/promotional-booklets" element={<PromotionalBooklets />}></Route>
                            <Route path="/product-catalogs" element={<ProductCatalog />}></Route>
                            <Route path="/contact" element={<Contacts />}></Route>
                            <Route path="/search" element={<Search />}></Route>
                            <Route path="/debts" element={<Debts />}></Route>
                            <Route path="/order/:id" element={<Order />}></Route>
                            <Route path="/document/:id" element={<Order />} />
                            <Route path="/orders" element={<Orders />}></Route>
                            <Route path="/process/:id" element={<Process />}></Route>
                            <Route path="/invoices" element={<Invoices />}></Route>
                            <Route path="/marketing" element={<Marketing />}></Route>
                            <Route path="/brands" element={<Brands />}></Route>
                            <Route path="/feedback-recieved/:id" element={<FeedbackComplete />}></Route>
                            <Route path="/feedback-status/:id" element={<FeedbackDetails />}></Route>
                            <Route path="/submit-feedback" element={<SubmitFeedback />}></Route>
                            <Route path="/feedback" element={<Feedback />}></Route>
                            <Route path="/shoppingcarts" element={<ShoppingCarts />}></Route>
                            <Route path="/shoppingcart/:id" element={<ShoppingCart />}></Route>
                            <Route path="/account" element={<Account />}></Route>
                            <Route path="/thankyou" element={<ThankYou />}></Route>
                            <Route path="/checkout" element={<Checkout />}></Route>
                            <Route path="/myshoppingcart" element={<MyShoppingCart />}></Route>
                            <Route path="/all-products" element={<AllProducts />}></Route>
                            <Route path="/productpage/:id" element={<ProductPage />}></Route>
                            <Route path="/" element={<MainPage />}></Route>
                            <Route path="*" element={<NoMatch />}></Route>
                        </Routes>
                    </>
                    :
                    <Routes>
                        <Route path="/" element={<LoginPhone />}></Route>
                        <Route path="/otp/" element={<OTPRead />}></Route>
                        <Route path="/recover-sms/" element={<PhoneRecovery />}></Route>
                        <Route path="/recover-email/" element={<EmailRecovery />}></Route>
                        <Route path="/select-account/" element={<SelectAccount />}></Route>
                        <Route path="/login-email/" element={<LoginEmail />}></Route>
                        <Route path="/enter-code/" element={<EnterCode />}></Route>
                        <Route path="/privacy-policy/" element={<PrivacyPolicy/>}></Route>
                        <Route path="/mandatory-disclosures/" element={<MandatoryDisclosures />}></Route>
                        {/* <Route path="*" element={<NoMatch />}></Route> */}
                    </Routes>
            }
        </Router>
    )
}

export default App
